import HomeView from "../HomeView.vue";
import HomePage from "../Pages/HomePage.vue";

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomePage,
        meta: {excludeAuth: true}
      }
    ]
  },
]

export default routes
