<template>
  <section
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div
      class="
        file-input
        d-flex
        flex-column flex-md-row
        justify-content-between
        align-items-center
      "
    >
      <div class="file-drop align-items-center p-3" v-if="files.length > 0">
        <p
          class="file-message my-0 text-truncate"
          v-for="(file, i) in files"
          :key="i"
        >
          <button
            class="bg-transparent border-0 delete-button"
            @click="deleteFile(i)"
            type="button"
          >
            <img
              src="@/core/assets/images/components/trash-icon.svg"
              alt="Borrar archivo"
            />
          </button>
          ({{ getHumanReadableSize(file.size) }}) {{ file.name }}
        </p>
      </div>
      <div class="file-drop d-flex align-items-center p-3" v-else>
        <p class="file-message my-0">{{ "Suelta tu archivos aquí..." }}</p>
      </div>
      <div class="file-button-container text-center">
        <section>
          <div style="height: 0px; overflow: hidden">
            <input
              type="file"
              :id="idFile"
              multiple="multiple"
              @change="onChange"
              ref="file"
              :accept="acceptFile"
            />
          </div>
          <button
            type="button"
            :disabled="isDisabled"
            @click="choseFile()"
            class="file-button"
            :class="{ 'file-button-disabled': isDisabled }"
          >
            <div class="button-overlay"></div>
            <span class="button-text">Buscar archivo</span>
          </button>
        </section>
      </div>
    </div>

    <div class="list-unstyled mt-4 text-danger" v-if="filesNotAllowed.length">Los siguientes archivos no están permitidos:</div>

    <ul class="list-unstyled mt-1">
      <li class="my-0" v-for="item in filesNotAllowed" :key="item.name">{{ item.name }}</li>
    </ul>
  </section>
</template>

<script>
import { Util } from "@/core/utils";

export default {
  name: "FormFile",
  props: {
    idFile: {
      type: String,
      required: true,
    },
    acceptFile: {
      type: Array,
      required: true,
    },
    asset: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    maxFiles: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      files: [],
      filesNotAllowed: [],
    };
  },
  methods: {
    onChange() {
      let filesList = Array.from(this.$refs.file.files);

      filesList = this.getAllowedFiles(filesList);

      this.emitChange(filesList);
    },
    getAllowedFiles(files) {
      let list = [];

      files.forEach((item) => {
        var file_ext =
          "." +
          item.name.substr(item.name.lastIndexOf(".") + 1, item.name.length);

        if (this.acceptFile.includes(file_ext)) {
          list.push(item);
        } else {
          let existFile = this.filesNotAllowed.find(file => file.name == item.name)
          if(!existFile) {
            this.filesNotAllowed.push(item)
          }
        }
      });

      return list;
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave() {},
    drop(event) {
      event.preventDefault();
      let list = Array.from(event.dataTransfer.files);
      list = this.getAllowedFiles(list);
      this.emitChange(list);
    },
    emitChange(files) {
      let hasChanged = false;
      files.forEach((o) => {
        if (this.files && this.files.length < this.maxFiles) {
          this.files.push(o);
          hasChanged = true;
        }
      });
      if (hasChanged) this.$emit("onChange", this.files);
    },
    getHumanReadableSize(size) {
      return Util.humanFileSize(size, true);
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
    choseFile() {
      this.$refs.file.click();
    },
    clearFiles() {
      this.files = [];
      this.$emit("onChange", this.files);
    },
  },
  async created() {},
  computed: {
    isDisabled: function () {
      return this.files.length == this.maxFiles;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../styles/components/file-input.styl';
</style>



