<template>
  <section class="form-container">
    <main class="main">
      <div class="info-content p-lg-0">
        <h1 class="title text-center">Libro de Reclamaciones</h1>

        <p class="description">
          Conforme a lo establecido en el Código de Protección y Defensa del
          Consumidor esta tienda cuenta con un Libro de Reclamaciones Virtual a
          tu disposición.
        </p>

        <p class="description">Los campos marcados con (*) son obligatorios.</p>

        <div class="business-info">
          <h2 class="subtitle text-center">Datos de la empresa</h2>
          <ul class="business-info list-unstyled mb-0">
            <li>
              <span>Razón social del proveedor:</span>
              {{ business.name }}
            </li>
            <li>
              <span>R.U.C.:</span>
              N° {{ business.identifier }}
            </li>

            <li>
              <span>Dirección del Establecimiento:</span>
              {{ business.address }}
            </li>
          </ul>
        </div>
      </div>

      <b-form @submit.prevent="() => {}" id="form" class="main-form" novalidate>
        <section class="section first">
          <h2 class="subtitle first text-center my-0">
            Información del Consumidor Reclamante
          </h2>

          <div class="row first-row no-gutters">
            <div class="col-md-6 pl-md-0">
              <b-form-group class="form-group-content">
                <form-select
                  defaultOption="Tipo de documento*"
                  :items="identifierTypes"
                  v-model="form.identifierType"
                  :class="{ 'form-error': $v.form.identifierType.$error }"
                />
                <FormError
                  v-if="
                    $v.form.identifierType.$error &&
                    !$v.form.identifierType.required
                  "
                  message="Tipo de documento es requerido"
                />
              </b-form-group>
            </div>

            <div class="col-md-6 pr-md-0">
              <b-form-group class="form-group-content">
                <input
                  type="text"
                  v-model.trim="form.identifier"
                  :maxlength="maxLengthIdentifier"
                  autocomplete="off"
                  placeholder="Número de documento*"
                  class="form-input"
                  :class="{ 'form-error': $v.form.identifier.$error }"
                />
                <FormError
                  v-if="
                    $v.form.identifier.$error && !$v.form.identifier.required
                  "
                  message="Número de documento es requerido"
                />
                <FormError
                  v-if="
                    $v.form.identifier.$error && !$v.form.identifier.numeric
                  "
                  message="No es un número de documento válido"
                />
              </b-form-group>
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-md-6 pl-md-0">
              <b-form-group class="form-group-content">
                <input
                  type="text"
                  maxlength="100"
                  v-model="form.firstName"
                  autocomplete="off"
                  placeholder="Nombres*"
                  class="form-input"
                  :class="{ 'form-error': $v.form.firstName.$error }"
                />
                <FormError
                  v-if="$v.form.firstName.$error && !$v.form.firstName.required"
                  message="Nombres son requeridos"
                />
              </b-form-group>
            </div>

            <div class="col-md-6 pr-md-0">
              <b-form-group class="form-group-content">
                <input
                  type="text"
                  maxlength="100"
                  v-model="form.lastName"
                  autocomplete="off"
                  placeholder="Apellidos*"
                  class="form-input"
                  :class="{ 'form-error': $v.form.lastName.$error }"
                />
                <FormError
                  v-if="$v.form.lastName.$error && !$v.form.lastName.required"
                  message="Apellidos son requeridos"
                />
              </b-form-group>
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-md-6 pl-md-0">
              <b-form-group class="form-group-content">
                <input
                  type="text"
                  v-model="form.mobile"
                  autocomplete="off"
                  placeholder="Celular*"
                  maxlength="20"
                  class="form-input"
                  :class="{ 'form-error': $v.form.mobile.$error }"
                />
                <FormError
                  v-if="$v.form.mobile.$error && !$v.form.mobile.required"
                  message="Celular es requerido"
                />
                <FormError
                  v-if="$v.form.mobile.$error && !$v.form.mobile.numeric"
                  message="Celular debe tener solo valores numéricos"
                />
                <FormError
                  v-if="$v.form.mobile.$error && !$v.form.mobile.mobile"
                  message="No es un celular válido"
                />
              </b-form-group>
            </div>

            <div class="col-md-6 pr-md-0">
              <b-form-group class="form-group-content">
                <input
                  type="text"
                  maxlength="100"
                  v-model.trim="form.email"
                  autocomplete="off"
                  placeholder="Correo electrónico*"
                  class="form-input"
                  :class="{ 'form-error': $v.form.email.$error }"
                />
                <FormError
                  v-if="$v.form.email.$error && !$v.form.email.required"
                  message="Correo electrónico es requerido"
                />
                <FormError
                  v-if="$v.form.email.$error && !$v.form.email.email"
                  message="No es un correo electrónico válido"
                />
              </b-form-group>
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-md-6 pl-md-0">
              <b-form-group class="form-group-content">
                <input
                  type="text"
                  v-model="form.address"
                  autocomplete="off"
                  placeholder="Dirección*"
                  class="form-input"
                  :class="{ 'form-error': $v.form.address.$error }"
                />
                <FormError
                  v-if="$v.form.address.$error && !$v.form.address.required"
                  message="Dirección es requerida"
                />
              </b-form-group>
            </div>

            <div class="col-md-6 pr-md-0">
              <b-form-group class="form-group-content">
                <form-select
                  defaultOption="Departamento*"
                  v-model="form.departmentKey"
                  :items="departments"
                  :class="{ 'form-error': $v.form.departmentKey.$error }"
                />
                <FormError
                  v-if="
                    $v.form.departmentKey.$error &&
                    !$v.form.departmentKey.required
                  "
                  message="Departamento es requerido"
                />
              </b-form-group>
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-md-6 pl-md-0">
              <b-form-group class="form-group-content mb-md-0">
                <form-select
                  defaultOption="Provincia*"
                  v-model="form.provinceKey"
                  :items="provinces"
                  :class="{ 'form-error': $v.form.provinceKey.$error }"
                />
                <FormError
                  v-if="
                    $v.form.provinceKey.$error && !$v.form.provinceKey.required
                  "
                  message="Provincia es requerida"
                />
              </b-form-group>
            </div>

            <div class="col-md-6 pr-md-0">
              <b-form-group class="form-group-content mb-0">
                <form-select
                  defaultOption="Distrito*"
                  v-model="form.districtKey"
                  :items="districts"
                  :class="{ 'form-error': $v.form.districtKey.$error }"
                />
                <FormError
                  v-if="
                    $v.form.districtKey.$error && !$v.form.districtKey.required
                  "
                  message="Distrito es requerido"
                />
              </b-form-group>
            </div>
          </div>

          <div class="switch-container ml-0 mb-0">
            <b-form-checkbox
              v-model="form.isUnderAge"
              name="check-button"
              switch
              size="md"
            >
              <span>Soy menor de edad</span>
            </b-form-checkbox>
          </div>

          <section v-if="form.isUnderAge">
            <div class="row first-row justify-content-center">
              <div class="col-12">
                <h2 class="subtitle underage-subtitle my-0">
                  Información del Apoderado
                </h2>

                <p class="underage-description mb-3">
                  (*) Toda comunicación relacionada con el reclamo/queja será
                  enviada a los datos de contacto del Apoderado.
                </p>
              </div>
            </div>
            <div class="row first-row no-gutters mt-0">
              <div class="col-md-6 pl-md-0">
                <b-form-group class="form-group-content">
                  <form-select
                    defaultOption="Tipo de documento*"
                    v-model="form.guardianIdentifierType"
                    :items="identifierTypes"
                    :class="{
                      'form-error': $v.form.guardianIdentifierType.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianIdentifierType.$error &&
                      !$v.form.guardianIdentifierType.required
                    "
                    message="Tipo de documento es requerido"
                  />
                </b-form-group>
              </div>

              <div class="col-md-6 pr-md-0">
                <b-form-group class="form-group-content">
                  <input
                    type="text"
                    v-model="form.guardianIdentifier"
                    :maxlength="maxLengthGuardianIdentifier"
                    autocomplete="off"
                    placeholder="Número de documento*"
                    class="form-input"
                    :class="{ 'form-error': $v.form.guardianIdentifier.$error }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianIdentifier.$error &&
                      !$v.form.guardianIdentifier.required
                    "
                    message="Número de documento es requerido"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianIdentifier.$error &&
                      !$v.form.guardianIdentifier.numeric
                    "
                    message="No es un número de documento válido"
                  />
                </b-form-group>
              </div>

              <div class="col-md-6 pl-md-0">
                <b-form-group class="form-group-content">
                  <input
                    type="text"
                    maxlength="100"
                    v-model="form.guardianFirstName"
                    autocomplete="off"
                    placeholder="Nombres*"
                    class="form-input"
                    :class="{ 'form-error': $v.form.guardianFirstName.$error }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianFirstName.$error &&
                      !$v.form.guardianFirstName.required
                    "
                    message="Nombres son requeridos"
                  />
                </b-form-group>
              </div>

              <div class="col-md-6 pr-md-0">
                <b-form-group class="form-group-content">
                  <input
                    type="text"
                    maxlength="100"
                    v-model="form.guardianLastName"
                    autocomplete="off"
                    placeholder="Apellidos*"
                    class="form-input"
                    :class="{ 'form-error': $v.form.guardianLastName.$error }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianLastName.$error &&
                      !$v.form.guardianLastName.required
                    "
                    message="Apellidos son requeridos"
                  />
                </b-form-group>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-md-6 pl-md-0">
                <b-form-group class="form-group-content">
                  <input
                    type="text"
                    maxlength="20"
                    v-model="form.guardianMobile"
                    autocomplete="off"
                    placeholder="Celular*"
                    class="form-input"
                    :class="{ 'form-error': $v.form.guardianMobile.$error }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianMobile.$error &&
                      !$v.form.guardianMobile.required
                    "
                    message="Celular es requerido"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianMobile.$error &&
                      !$v.form.guardianMobile.numeric
                    "
                    message="Celular debe tener solo valores numéricos"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianMobile.$error &&
                      !$v.form.guardianMobile.mobile
                    "
                    message="No es un celular válido"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6 pr-md-0">
                <b-form-group class="form-group-content">
                  <input
                    type="text"
                    maxlength="100"
                    v-model="form.guardianEmail"
                    autocomplete="off"
                    placeholder="Correo electrónico*"
                    class="form-input"
                    :class="{ 'form-error': $v.form.guardianEmail.$error }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianEmail.$error &&
                      !$v.form.guardianEmail.required
                    "
                    message="Correo electrónico es requerido"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianEmail.$error &&
                      !$v.form.guardianEmail.email
                    "
                    message="No es un correo electrónico válido"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6 pl-md-0">
                <b-form-group class="form-group-content">
                  <input
                    type="text"
                    v-model="form.guardianAddress"
                    autocomplete="off"
                    placeholder="Dirección*"
                    class="form-input"
                    :class="{ 'form-error': $v.form.guardianAddress.$error }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianAddress.$error &&
                      !$v.form.guardianAddress.required
                    "
                    message="Dirección es requerida"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6 pr-md-0">
                <b-form-group class="form-group-content">
                  <form-select
                    defaultOption="Departamento*"
                    :items="departments"
                    v-model="form.guardianDepartmentKey"
                    :class="{
                      'form-error': $v.form.guardianDepartmentKey.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianDepartmentKey.$error &&
                      !$v.form.guardianDepartmentKey.required
                    "
                    message="Departamento es requerido"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-md-6 pl-md-0">
                <b-form-group class="form-group-content">
                  <form-select
                    defaultOption="Provincia*"
                    v-model="form.guardianProvinceKey"
                    :items="guardianProvinces"
                    :class="{
                      'form-error': $v.form.guardianProvinceKey.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianProvinceKey.$error &&
                      !$v.form.guardianProvinceKey.required
                    "
                    message="Provincia es requerida"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6 pr-md-0">
                <b-form-group class="form-group-content mb-0">
                  <form-select
                    defaultOption="Distrito*"
                    v-model="form.guardianDistrictKey"
                    :items="guardianDistricts"
                    :class="{
                      'form-error': $v.form.guardianDistrictKey.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.form.guardianDistrictKey.$error &&
                      !$v.form.guardianDistrictKey.required
                    "
                    message="Distrito es requerido"
                  />
                </b-form-group>
              </div>
            </div>
          </section>
        </section>

        <section class="section well-hired">
          <h2 class="subtitle text-center my-0">
            Identificación del Bien Contratado
          </h2>

          <div class="row first-row no-gutters">
            <div class="col-md-6 pl-md-0">
              <b-form-group class="form-group-content">
                <form-select
                  defaultOption="Bien contratado*"
                  :items="wellTypes"
                  v-model="form.wellType"
                  :class="{ 'form-error': $v.form.wellType.$error }"
                />
                <FormError
                  v-if="$v.form.wellType.$error && !$v.form.wellType.required"
                  message="Bien contratado es requerido"
                />
              </b-form-group>
            </div>

            <div class="col-md-6 pr-md-0">
              <b-form-group class="form-group-content">
                <input
                  type="number"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="15"
                  min="0"
                  v-model.trim="form.wellAmount"
                  autocomplete="off"
                  placeholder="Monto reclamado en soles*"
                  class="form-input"
                  :class="{ 'form-error': $v.form.wellAmount.$error }"
                />
                <FormError
                  v-if="
                    $v.form.wellAmount.$error && !$v.form.wellAmount.required
                  "
                  message="Monto reclamado en soles es requerido"
                />
                <FormError
                  v-if="
                    $v.form.wellAmount.$error && !$v.form.wellAmount.decimal
                  "
                  message="No es un monto válido"
                />
              </b-form-group>
            </div>
          </div>

          <div class="row justify-content-center no-gutters">
            <div class="col-12 pl-md-0">
              <b-form-group class="form-group-content mb-0">
                <textarea
                  class="form-input"
                  maxlength="5000"
                  placeholder="Descripción*"
                  v-model="form.wellDescription"
                  :class="{ 'form-error': $v.form.wellDescription.$error }"
                ></textarea>
                <FormError
                  v-if="
                    $v.form.wellDescription.$error &&
                    !$v.form.wellDescription.required
                  "
                  message="Descripción es requerida"
                />
              </b-form-group>
            </div>
          </div>
        </section>

        <section class="section claim">
          <h2 class="subtitle text-center mt-0">Detalle de su reclamo</h2>

          <div class="p-0">
            <p class="my-0">
              <span class="claim-label">Reclamo:</span> Cuando el consumidor no
              está conforme con los bienes adquiridos o servicios prestados.
            </p>
            <p>
              <span class="claim-label">Queja:</span> Cuando el consumidor
              expresa su malestar respecto de algún tema que no tenga que ver
              directamente con el giro del negocio, ejemplo, mala atención.
            </p>
          </div>

          <div class="row justify-content-center first-row no-gutters mt-0">
            <div class="col-12 pl-md-0">
              <b-form-group class="form-group-content">
                <form-select
                  defaultOption="Tipo de reporte*"
                  :items="complaintTypes"
                  v-model="form.type"
                  class="form-select-large"
                  :class="{ 'form-error': $v.form.type.$error }"
                />
                <FormError
                  v-if="$v.form.type.$error && !$v.form.type.required"
                  message="Tipo de reporte es requerido"
                />
              </b-form-group>
            </div>

            <div class="col-12 pr-md-0">
              <b-form-group class="form-group-content mb-0">
                <textarea
                  class="form-input"
                  placeholder="Descripción*"
                  maxlength="5000"
                  v-model="form.description"
                  :class="{ 'form-error': $v.form.description.$error }"
                ></textarea>
                <FormError
                  v-if="
                    $v.form.description.$error && !$v.form.description.required
                  "
                  message="Descripción es requerida"
                />
              </b-form-group>
            </div>
          </div>
        </section>

        <section class="section files">
          <h2 class="subtitle text-center my-0">Archivos adjuntos</h2>

          <div class="p-0">
            <p class="description mt-2">
              Puede incluir a su reclamo/queja un máximo de
              {{ maxFiles }} documentos de hasta {{ maxFileSize }}MB cada uno.
              Formatos admitidos: pdf, docx, doc, png, jpg, jpeg.
            </p>

            <div>
              <FormFile
                idFile="complaint-files"
                :acceptFile="['.pdf', '.docx', '.doc', '.png', '.jpg', '.jpeg']"
                ref="formFile"
                :maxFiles="maxFiles"
                @onChange="onChangeFiles"
              />
              <FormError
                class="file-error"
                v-if="$v.form.files.$error && !$v.form.files.fileSize"
                :message="`Cada archivo debe pesar ${maxFileSize}MB como máximo`"
              />
              <FormError
                class="file-error"
                v-if="$v.form.files.$error && !$v.form.files.fileType"
                message="Alguno de los archivos no tiene un formato válido"
              />
            </div>
          </div>
        </section>

        <Observations
          title="Observaciones y acciones adoptadas por el Proveedor:"
          :business="business"
        />

        <section class="section text-md-left pt-0 p-md-0 bg-transparent">
          <div class="terms-container">
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.acceptTerms"
              value="accepted"
              unchecked-value="not_accepted"
            >
              <span>
                Declaro que los datos consignados son <br class="d-md-none"> correctos y fiel expresión
                de la verdad.
              </span>
            </b-form-checkbox>
          </div>
          <FormError
            v-if="$v.form.acceptTerms.$error && !$v.form.acceptTerms.checked"
            message="Debe aceptar la declaración de datos"
          />
        </section>

        <div class="submit-container text-center">
          <vue-recaptcha :sitekey="recaptchaSiteKey"
            ><input
              type="button"
              @click="save"
              value="ENVIAR FORMULARIO"
              class="button button-primary"
          /></vue-recaptcha>
        </div>
      </b-form>

      <div class="info-content p-lg-0">
        <ZetBanner />
      </div>

      <Footer />
    </main>
    <ComplaintModal :show="showModal" @hide="hideModal" />
  </section>
</template>

<script>
import { FormError, FormFile, FormSelect } from "@/core/components";
import { Constants, Validators } from "@/core/utils";
import { ComplaintService, UbigeoService } from "@/core/services";
import Observations from "@/modules/Complaint/Components/Observations.vue";
import ZetBanner from "./ZetBanner.vue";
import Footer from "./Footer.vue";
import {
  decimal,
  email,
  numeric,
  required,
  requiredIf,
} from "vuelidate/lib/validators";
import ComplaintModal from "./ComplaintModal.vue";
import { mapGetters } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "ComplaintForm",
  components: {
    FormSelect,
    FormFile,
    FormError,
    ComplaintModal,
    VueRecaptcha,
    Observations,
    ZetBanner,
    Footer,
  },
  props: {
    business: Object,
  },
  computed: {
    ...mapGetters({
      isLoading: "app/isLoading",
    }),
    maxLengthIdentifier: function () {
      if (this.form.identifierType) {
        const type = this.identifierTypes.find(
          (i) => i.key === this.form.identifierType
        );
        return type.length;
      }
      return 11;
    },
    maxLengthGuardianIdentifier: function () {
      if (this.form.guardianIdentifierType) {
        const type = this.identifierTypes.find(
          (i) => i.key === this.form.guardianIdentifierType
        );
        return type.length;
      }
      return 11;
    },
  },
  data() {
    return {
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY,
      identifierTypes: [
        Constants.IdentifierType.DNI,
        Constants.IdentifierType.CE,
        Constants.IdentifierType.RUC,
        Constants.IdentifierType.PASSPORT,
      ],
      wellTypes: [Constants.WellType.PRODUCT, Constants.WellType.SERVICE],
      complaintTypes: [
        Constants.ComplaintType.CLAIM,
        Constants.ComplaintType.COMPLAINT,
      ],
      showModal: false,
      ubigeos: [],
      departments: [],
      provinces: [],
      districts: [],
      guardianProvinces: [],
      guardianDistricts: [],
      maxFileSize: Constants.FileProps.MAX_FILE_SIZE,
      maxFiles: Constants.FileProps.MAX_FILES,
      form: {
        identifierType: null,
        identifier: null,
        firstName: null,
        lastName: null,
        mobile: null,
        email: null,
        address: null,
        departmentKey: null,
        provinceKey: null,
        districtKey: null,
        isUnderAge: false,
        guardianIdentifierType: null,
        guardianIdentifier: null,
        guardianFirstName: null,
        guardianLastName: null,
        guardianMobile: null,
        guardianEmail: null,
        guardianAddress: null,
        guardianDepartmentKey: null,
        guardianProvinceKey: null,
        guardianDistrictKey: null,
        wellType: null,
        wellAmount: null,
        wellDescription: null,
        type: null,
        description: null,
        acceptTerms: "not_accepted",
        files: [],
      },
    };
  },
  watch: {
    "form.departmentKey"(newVal) {
      if (newVal) {
        this.provinces = this.ubigeos.filter((o) => o.parentId === newVal);
      } else {
        this.provinces = [];
      }
      this.form.districtKey = null;
      this.form.provinceKey = null;
    },
    "form.provinceKey"(newVal) {
      if (newVal) {
        this.districts = this.ubigeos.filter((o) => o.parentId === newVal);
      } else {
        this.districts = [];
      }
      this.form.districtKey = null;
    },
    "form.guardianDepartmentKey"(newVal) {
      if (newVal) {
        this.guardianProvinces = this.ubigeos.filter(
          (o) => o.parentId === newVal
        );
      } else {
        this.guardianProvinces = [];
      }
      this.form.guardianProvinceKey = null;
      this.form.guardianDistrictKey = null;
    },
    "form.guardianProvinceKey"(newVal) {
      if (newVal) {
        this.guardianDistricts = this.ubigeos.filter(
          (o) => o.parentId === newVal
        );
      } else {
        this.guardianDistricts = [];
      }
      this.form.guardianDistrictKey = null;
    },
    "form.identifierType"() {
      this.form.identifier = null;
    },
    "form.guardianIdentifierType"() {
      this.form.guardianIdentifier = null;
    },
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -30,
          });
        });
        return;
      }
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { ...this.form };

        if (!data.files || data.files.length === 0) {
          delete data.files;
        }

        data.district = this.ubigeos.find(
          (o) => o.key === data.districtKey
        ).label;
        data.province = this.ubigeos.find(
          (o) => o.key === data.provinceKey
        ).label;
        data.department = this.ubigeos.find(
          (o) => o.key === data.departmentKey
        ).label;
        if (data.isUnderAge) {
          data.guardianDistrict = this.ubigeos.find(
            (o) => o.key === data.guardianDistrictKey
          ).label;
          data.guardianProvince = this.ubigeos.find(
            (o) => o.key === data.guardianProvinceKey
          ).label;
          data.guardianDepartment = this.ubigeos.find(
            (o) => o.key === data.guardianDepartmentKey
          ).label;
        }
        data.slug = this.$route.params.slug;
        await ComplaintService.saveComplaint(data);
        this.showModal = true;
      } catch (e) {
        console.error(e);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    onChangeFiles(files) {
      this.$v.form.files.$reset();
      this.form.files = files;
    },
    hideModal() {
      this.showModal = false;
      this.clearForm();
      this.$nextTick(() => {
        this.$smoothScroll({
          scrollTo: document.querySelector("#app"),
          updateHistory: false,
        });
      });
    },
    clearForm() {
      this.form.identifierType = null;
      this.form.identifier = null;
      this.form.firstName = null;
      this.form.lastName = null;
      this.form.mobile = null;
      this.form.email = null;
      this.form.address = null;
      this.form.departmentKey = null;
      this.form.provinceKey = null;
      this.form.districtKey = null;
      this.form.isUnderAge = false;
      this.form.guardianIdentifierType = null;
      this.form.guardianIdentifier = null;
      this.form.guardianFirstName = null;
      this.form.guardianLastName = null;
      this.form.guardianMobile = null;
      this.form.guardianEmail = null;
      this.form.guardianAddress = null;
      this.form.guardianDepartmentKey = null;
      this.form.guardianProvinceKey = null;
      this.form.guardianDistrictKey = null;
      this.form.wellType = null;
      this.form.wellAmount = null;
      this.form.wellDescription = null;
      this.form.type = null;
      this.form.description = null;
      this.form.acceptTerms = null;
      this.$refs.formFile.clearFiles();
      this.$v.form.$reset();
    },
  },
  validations: {
    form: {
      identifierType: { required },
      identifier: { required, numeric },
      firstName: { required },
      lastName: { required },
      mobile: { required, numeric, mobile: Validators.mobile },
      email: { required, email },
      address: { required },
      departmentKey: { required },
      provinceKey: { required },
      districtKey: { required },
      guardianIdentifierType: {
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianIdentifier: {
        numeric,
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianFirstName: {
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianLastName: {
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianMobile: {
        numeric,
        mobile: Validators.mobile,
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianEmail: {
        email,
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianAddress: {
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianDepartmentKey: {
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianProvinceKey: {
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      guardianDistrictKey: {
        required: requiredIf(function () {
          return this.form.isUnderAge;
        }),
      },
      wellType: { required },
      wellAmount: { decimal, required },
      wellDescription: { required },
      type: { required },
      description: { required },
      acceptTerms: { checked: (value) => value === "accepted" },
      files: {
        fileSize: Validators.maxFileSize(Constants.FileProps.MAX_FILE_SIZE),
        fileType: Validators.fileType(Constants.FileProps.MIME_TYPES),
      },
    },
  },
  async created() {
    const data = await UbigeoService.getUbigeos();
    this.ubigeos = data.payload.map((o) => {
      return { key: o.id, label: o.description, parentId: o.parentId };
    });
    this.departments = this.ubigeos.filter((o) => !o.parentId);
  },
};
</script>

<style lang="stylus">
@import '../Styles/form.styl';
</style>
