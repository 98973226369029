/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

function getFormData(data) {
  const formData = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key]) && data[key].length > 0) {
      for (const index in data[key]) {
        const dataKeyIndex = data[key][index];
        if (typeof dataKeyIndex === 'string' || typeof dataKeyIndex === 'number') {
          formData.append(`${key}[${index}]`, data[key][index]);
        } else if (dataKeyIndex instanceof File) {
          formData.append(`${key}`, data[key][index]);
        } else {
          for (const key2 in data[key][index]) {
            formData.append(`${key}[${index}].${key2}`, data[key][index][key2] ? data[key][index][key2] : '');
          }
        }
      }
    } else {
      formData.append(key, data[key] ? data[key] : data[key] == null ? '' : data[key]);
    }
  }
  return formData;
}

function searchValue(key, object) {
  for (let val in object) {
    const obj = object[val];
    if (obj.key === key)
      return obj;
  }
  return {}
}

const formatNumber = function (val, fractionDigits = 2) {
  const d = parseFloat(val);
  if (!isNaN(d)) {
    return d.toLocaleString('en', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  } else {
    return '';
  }
}

export default {
  humanFileSize,
  getFormData,
  searchValue,
  formatNumber
}
