<template>
  <div>
    <section class="login">
      <LoginHeader />
      <main class="d-flex justify-content-center align-items-center">
        <div
          class="
            container
            d-flex
            justify-content-center
            align-items-center
            d-md-block
            justify-content-md-start
            align-items-md-start
          "
        >
          <div class="row justify-content-center">
            <section
              class="
                col-11 col-md-6 col-lg-5
                order-1 order-md-0
                text-center text-md-left
                pt-5
                pt-md-0
                animate__animated animate__fadeIn
              "
            >
              <h1 class="title font-weight-normal">
                La mejor plataforma para la gestión de tus reclamaciones
              </h1>
              <p class="description text-secondary">
                Gestiona y responde rápidamente las quejas y reclamos de tus
                clientes desde cualquier lugar donde te encuentres.
              </p>

              <section
                class="
                  d-flex
                  flex-column
                  align-items-center align-items-md-start
                  mt-4
                "
              >
                <AccessButton />

                <router-link to="/" class="button-blue mt-3"
                  >Ir a documentación</router-link
                >
              </section>
            </section>

            <section
              class="
                col-md-6 col-lg-7
                order-0 order-md-1
                d-flex
                justify-content-center
                align-items-center
                position-relative
              "
            >
              <img
                src="@/core/assets/images/home/imagen-principal.svg"
                alt="Imagen de bienvenida"
                class="img-fluid banner-img animate__animated animate__fadeIn"
              />
            </section>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script>
import LoginHeader from "../Components/Header";
import AccessButton from "../Components/AccessButton";

export default {
  name: "LoginPage",
  components: {
    AccessButton,
    LoginHeader,
  },
  data() {
    return {
      error: false,
      errorMsg: null,
    };
  },
  methods: {
    loginSso() {
      this.errorMsg = null;
      this.loading = true;
    },
  },
  mounted() {
    if (window.location.search) this.loginSso();
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/home-page';
</style>



