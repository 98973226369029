<template>
  <section class="zet-info text-center text-md-left">
    <div class="row">
      <div class="col-md-8 px-0">
        <h3 class="zet-info-subtitle mb-0">Obtén tu Libro de Reclamaciones</h3>

        <p class="description">
          ZET, la mejor plataforma para la gestión de reclamos.
        </p>
        <router-link
          :to="{ name: 'home' }"
          target="_blank"
          class="button button-zet"
          >Conoce más</router-link
        >
      </div>

      <div class="col-md-4">
        <img src="@/core/assets/images/zet-frame.svg" alt="Zet" class="image" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'ZetBanner'
};
</script>

<style lang="stylus">
@import "../Styles/zet-banner.styl"
</style>
