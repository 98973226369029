import {LocalStorage} from '../core/utils'

const state = {
  refreshToken: LocalStorage.getRefreshToken(),
  accessToken: LocalStorage.getAccessToken(),
  user: LocalStorage.getUser(),
  ssoState: LocalStorage.getSsoState()
}

const getters = {
  isAuthenticated: state => state.user && state.user.email,
  getUser: state => state.user,
  getSsoState: state => state.ssoState
};

const actions = {
  setAuthInfo({commit}, payload) {
    const {accessToken, user, refreshToken} = payload;
    commit('accessToken', accessToken);
    commit('user', user);
    commit('refreshToken', refreshToken);
  },
  logout({commit}) {
    return new Promise((resolve) => {
      commit('accessToken', null)
      commit('user', null)
      commit('refreshToken', null)
      resolve()
    })
  }
};

const mutations = {
  accessToken(state, value) {
    value ? LocalStorage.setAccessToken(value) : LocalStorage.clearAccessToken();
    state.accessToken = value;
  },
  refreshToken(state, value) {
    value ? LocalStorage.setRefreshToken(value) : LocalStorage.clearRefreshToken();
    state.refreshToken = value;
  },
  user(state, value) {
    value ? LocalStorage.setUser(value) : LocalStorage.clearUser();
    state.user = value;
  },
  ssoState(state, value) {
    value ? LocalStorage.setSsoState(value) : LocalStorage.clearSsoState();
    state.ssoState = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
