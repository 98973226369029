import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeRoutes from '@/modules/Home/Routes'
import ComplaintRoutes from '@/modules/Complaint/Routes'

Vue.use(VueRouter);

let routes = [];

routes = routes.concat(HomeRoutes, ComplaintRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router
