<template>
  <div class="info-badge text-center d-flex justify-content-center align-items-center">
      <img src="@/core/assets/images/components/alert-icon.svg" :alt="text">
      <p class="my-0 ml-2">{{ text }}</p>
  </div>
</template>

<script>
export default {
    name: 'InfoBadge',
    props: {
        text: String
    }
}
</script>

<style lang="stylus" scoped>
@import "../Styles/info-badge.styl"
</style>