<template>
  <section class="complaint-detail animate__animated animate__fadeIn">
    <Loader :loading="isLoading" />
    <Header :business="business" />

    <div class="complaint-container px-0">
      <h1 class="title text-center" v-if="complaint.code">Reclamo N° {{complaint.refNumber}}</h1>

      <div class="main">
        <template v-if="complaint.code">
          <h2 class="subtitle text-center">Datos de la empresa</h2>

          <ul class="business-info list-unstyled mb-0">
            <li>
              <span>Razón social del proveedor:</span>
              {{ business.name }}
            </li>

            <li>
              <span>R.U.C.:</span>
              N° {{ business.identifier }}
            </li>

            <li>
              <span>Dirección del Establecimiento:</span>
              {{ business.address }}
            </li>
          </ul>

          <section class="section first">
            <h2 class="subtitle first text-center my-0">
              Información del Consumidor Reclamante
            </h2>

            <div>
              <div class="row first-row no-gutters">
                <div class="col-md-6">
                  <span class="label">Tipo de Documento</span>
                  <p class="text">{{ getIdentifierType(complaint.identifierType).label }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Número de documento</span>
                  <p class="text">{{ complaint.identifier }}</p>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-6">
                  <span class="label">Nombres</span>
                  <p class="text">{{ complaint.firstName }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Apellidos</span>
                  <p class="text">{{ complaint.lastName }}</p>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-6">
                  <span class="label">Celular</span>
                  <p class="text">{{ complaint.mobile }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Correo electrónico</span>
                  <p class="text">{{ complaint.email }}</p>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-6">
                  <span class="label">Dirección</span>
                  <p class="text">{{ complaint.address }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Departamento</span>
                  <p class="text">{{ complaint.department }}</p>
                </div>
              </div>

              <div class="row no-gutters mb-0">
                <div class="col-md-6">
                  <span class="label">Provincia</span>
                  <p class="text">{{ complaint.province }}</p>
                </div>

                <div class="col-md-6 mb-0">
                  <span class="label">Distrito</span>
                  <p class="text">{{ complaint.district }}</p>
                </div>
              </div>
            </div>

            <div v-if="complaint.isUnderAge">
              <h2 class="subtitle-2 first underage mb-0">
                Información del Apoderado
              </h2>

              <div class="row first-row no-gutters">
                <div class="col-md-6">
                  <span class="label">Tipo de Documento</span>
                  <p class="text">{{ getIdentifierType(complaint.guardianIdentifierType).label }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Número de documento</span>
                  <p class="text">{{ complaint.guardianIdentifier }}</p>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-6">
                  <span class="label">Nombres</span>
                  <p class="text">{{ complaint.guardianFirstName }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Apellidos</span>
                  <p class="text">{{ complaint.guardianLastName }}</p>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-6">
                  <span class="label">Celular</span>
                  <p class="text">{{ complaint.guardianMobile }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Correo electrónico</span>
                  <p class="text">{{ complaint.guardianEmail }}</p>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-6">
                  <span class="label">Dirección</span>
                  <p class="text">{{ complaint.guardianAddress }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Departamento</span>
                  <p class="text">{{ complaint.guardianDepartment }}</p>
                </div>
              </div>

              <div class="row no-gutters mb-0">
                <div class="col-md-6">
                  <span class="label">Provincia</span>
                  <p class="text">{{ complaint.guardianProvince }}</p>
                </div>

                <div class="col-md-6 mb-0">
                  <span class="label">Distrito</span>
                  <p class="text">{{ complaint.guardianDistrict }}</p>
                </div>
              </div>
            </div>
          </section>

          <section class="section secondary">
            <h2 class="subtitle text-center mt-0">
              Identificación del Bien Contratado
            </h2>

            <div>
              <div class="row no-gutters">
                <div class="col-md-6">
                  <span class="label">Bien contratado</span>
                  <p class="text">{{ getWeelType(complaint.wellType).label }}</p>
                </div>

                <div class="col-md-6">
                  <span class="label">Monto reclamado en soles</span>
                  <p class="text" v-decimal:pen="complaint.wellAmount"></p>
                </div>
              </div>

              <div class="row no-gutters mb-0">
                <div class="col-md-12 mb-0">
                  <span class="label">Descripción</span>
                  <p class="text">
                    {{ complaint.wellDescription }}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section class="section secondary">
            <h2 class="subtitle text-center mt-0">
              Detalle de su reclamo
            </h2>

            <div>
              <div class="row no-gutters">
                <div class="col-md-6">
                  <span class="label">Tipo de reporte </span>
                  <p class="text">{{ getReportType(complaint.type).label }}</p>
                </div>
              </div>

              <div class="row no-gutters mb-0">
                <div class="col-md-12 mb-0">
                  <span class="label">Descripción</span>
                  <p class="text">
                    {{ complaint.description }}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section class="section secondary">
            <h2 class="subtitle text-center mt-0">
              Archivos adjuntos
            </h2>

            <div>
              <div class="row no-gutters mb-0">
                <div class="col-md-12 mb-0">
                  <span class="label">Descargar documentos</span>
                  <ul class="attachment-list list-unstyled mt-2 mb-0" v-if="complaint.attachments.length">
                      <li v-for="item in complaint.attachments" :key="item.id">
                          <img src="@/core/assets/images/components/download-icon.svg" alt="Descargar archivo">
                          <a href="" class="text-decoration-none d-inline-block ml-2" @click.prevent="downloadFile(item.url, item.name)">{{ item.name }}</a>
                      </li>
                  </ul>

                  <InfoBadge text="Este reclamo no tiene archivos adjuntos" class="mt-4" v-else />
                </div>
              </div>
            </div>
          </section>

          <section class="section secondary">
            <h2 class="subtitle text-center mt-0">
              Observaciones y acciones adoptadas por el Proveedor
            </h2>

            <div>
              <div class="row no-gutters mb-0">
                <div class="col-md-12 mb-0">
                  <InfoBadge v-if="complaint.status === ComplaintsStatus.PENDING.key" text="Este reclamo aún no tiene respuesta" />
                  <template v-else>
                    <p class="my-0" v-if="complaint.response">
                      {{ complaint.response }}
                    </p>
                    <InfoBadge v-else text="Sin acciones tomadas por el proveedor." />
                  </template>
                </div>
              </div>
            </div>
          </section>

          <Observations title="Consideraciones:" :business="business" />
        </template>

        <ZetBanner />

        <Footer />
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { Util, Constants } from '@/core/utils'
import { Header, Loader } from "@/core/components";
import InfoBadge from '../Components/InfoBadge.vue'
import Observations from '@/modules/Complaint/Components/Observations.vue'
import ZetBanner from '../Components/ZetBanner.vue'
import Footer from '../Components/Footer.vue'
import { BusinessService, ComplaintService } from "@/core/services";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    Loader,
    InfoBadge,
    Observations,
    ZetBanner,
    Footer
  },
  computed: {
    ...mapGetters({
      isLoading: "app/isLoading",
    }),
  },
  data() {
    return {
      loading: true,
      business: {},
      complaint: {},
      ComplaintsStatus: Constants.ComplaintsStatus,
    };
  },
  async beforeMount() {
    try {
      await this.$store.dispatch("app/loading", true);
      const response = await BusinessService.getBusiness(this.$route.params.slug)
      this.business = response.payload;
      document.documentElement.style.setProperty('--color-primary', this.business.primaryColor ? this.business.primaryColor : '#0070C9');
    } catch (ex) {
      console.error('Error getting business info', ex)
      await this.$router.push({name: "home"});
    } finally {
      await this.$store.dispatch("app/loading", false);
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      try {
        this.$store.dispatch("app/loading", true);
        const params = { code: this.$route.params.id };
        const response = await ComplaintService.get(params);
        this.complaint = { ...response.payload };
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    getWeelType(key) {
      return Util.searchValue(key, Constants.WellType);
    },
    getReportType(key) {
      return Util.searchValue(key, Constants.ComplaintType);
    },
    async downloadFile(url, filename) {
      const response = await axios.get(url, { responseType: "blob" });
      let headers = response.headers;
      const blob = new Blob([response.data], { type: headers["content-type"] });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }
};
</script>

<style lang="stylus">
@import "../Styles/complaint-detail.styl"
</style>
