import ComplaintView from "../ComplaintView.vue";
import ComplaintPage from "../Pages/ComplaintPage.vue";
import ComplaintDetail from "../Pages/ComplaintDetail.vue";

const routes = [
  {
    path: '/:slug',
    component: ComplaintView,
    children: [
      {
        path: '',
        name: 'complaint',
        component: ComplaintPage,
      },
      {
        path: ':id',
        name: 'complaint-detail',
        component: ComplaintDetail
      }
    ]
  }
]

export default routes
