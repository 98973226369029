import { helpers } from 'vuelidate/lib/validators'
import Constants from "./constants";


const maxFileSize = (numberMegaBytes) => (value) =>  {
  if (!value) {
    return true;
  }
  let isValid = true;
  const factor = Constants.MEGA_TO_BYTES_FACTOR * numberMegaBytes;
  value.forEach((file)=>{
    if(file.size > factor){
      isValid =false;
    }
  })
  return isValid;
};

const fileType = (typeFiles) => (value) =>  {
  if (!value) {
    return true;
  }
  let isValid = true;
  value.forEach((file)=>{
    if(!typeFiles.find(element => element === file.type)){
      isValid =false;
    }
  })
  return isValid;
};

const greaterThan = (param) =>
  helpers.withParams(
    {type: 'greaterThan', value: param},
    (value) => !helpers.req(value) || value > param
  )

const url = (url) => /(https?:\/\/)(www\.)?(?!www)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&/=]*)/.test(url);

const mobile = (value,) => {
  if (!value) return true;
  const regx = RegExp('^[0-9]*$');
  return regx.test(value) && value.length === 9;
};

export default {
  maxFileSize,
  fileType,
  url,
  greaterThan,
  mobile
}

