const Errors = {
  SERVER_ERROR: "Ocurrió un error en la petición, por favor intenta nuevamente.",
}

const IdentifierType = {
  DNI: {key: 1, label: 'DNI', length: 8},
  CE: {key: 2, label: 'CE', length: 12},
  RUC: {key: 3, label: 'RUC', length: 11},
  PASSPORT: {key: 4, label: 'PASAPORTE', length: 12},
}

const ComplaintsStatus = {
  PENDING: { key: 1, label: 'PENDIENTE' },
  RESPONDED: { key: 2, label: 'ATENDIDO'}
}

const WellType = {
  PRODUCT: {key: 1, label: 'PRODUCTO'},
  SERVICE: {key: 2, label: 'SERVICIO'}
}

const ComplaintType = {
  COMPLAINT: {key: 1, label: 'QUEJA'},
  CLAIM: {key: 2, label: 'RECLAMO'}
}

const FileProps = {
  MIME_TYPES: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'officedocument.wordprocessingml.document'],
  MAX_FILE_SIZE: 5,
  MAX_FILES: 5
}

const MEGA_TO_BYTES_FACTOR = 1048576;

const Currencies = {
  PEN: 'S/',
}

export default {
  Errors,
  IdentifierType,
  WellType,
  ComplaintType,
  FileProps,
  MEGA_TO_BYTES_FACTOR,
  Currencies,
  ComplaintsStatus
}
