<template>
  <section class="loading loading-bg-primary d-flex justify-content-center align-items-center" v-if="loading">
    <div class="text-center pl-md-5">
      <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
      </div>
      <p class="title mt-3">Cargando, un momento por favor...</p>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Loader',
    props: {
      loading: Boolean
    }
}
</script>

<style lang="stylus" scoped>
@import "../styles/components/loader"
</style>