function getRandomState() {
  let state = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 36; i++) {
    state += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return state;
}


function getCallbackParameters(href) {
  const url = new URL(href);
  const state = url.searchParams.get('state');
  const sessionState = url.searchParams.get('session_state');
  const code = url.searchParams.get('code');
  const redirectUri = process.env.VUE_APP_SSO_AUTH_REDIRECT_URI;
  return {
    state,
    sessionState,
    code,
    redirectUri
  }
}

export default {
  getRandomState,
  getCallbackParameters
}
